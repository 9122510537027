<template>
  <div
    id="footer-layout"
    class="bg-gray-800 text-white flex flex-col justify-center items-center text-center p-4 mt-8"
  >
    <p>Hecho con <span class="text-red-400">❤</span></p>
    <p>&copy; adopten.me</p>
  </div>
</template>

<script>
export default {
  name: 'FooterLayout'
}
</script>

<style></style>
