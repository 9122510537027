<template>
  <div id="app">
    <nav-bar-layout></nav-bar-layout>
    <router-view></router-view>
    <footer-layout></footer-layout>
  </div>
</template>

<script>
import NavBarLayout from '@/modules/nav-bar/layout/NavBarLayout.vue'
import FooterLayout from '@/modules/footer/layout/FooterLayout.vue'

export default {
  name: 'App',
  components: {
    NavBarLayout,
    FooterLayout
  },
}
</script>
