import { createRouter, createWebHashHistory } from 'vue-router'
import homeRouter from '../modules/home/router'
import adoptenmeRouter from '../modules/adoptenme/router'
import petRegisterRouter from '../modules/pet-register/router'

const routes = [
  {
    path: '/',
    ...homeRouter
  },
  {
    path: '/adoptenme',
    ...adoptenmeRouter
  },
  {
    path: '/registro-mascota',
    ...petRegisterRouter
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
