<template>
  <button
    id="nav-link"
    @click="onNavigation"
    :class="[
      active ? 'text-black' : 'text-gray-500',
      'hover:text-gray-600 duration-200 font-semibold p-2'
    ]"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'NavLink',
  props: {
    text: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    },
    route: {
      type: String,
      default: 'Home'
    }
  },
  methods: {
    async onNavigation() {
      const route = this.route
      await this.$router.push({
        name: route
      })
    }
  }
}
</script>

<style></style>
