<template>
  <div id="nav-bar" class="bg-orange-50 w-full">
    <div
      class="flex flex-row justify-between items-center container py-4 mx-auto"
    >
      <div>
        <h1 class="font-bold text-2xl">
          adopten<span class="text-orange-600">.me</span>
        </h1>
      </div>
      <div class="flex flex-row justify-around">
        <nav-link route="Home" text="Inicio" :active="true"></nav-link>
        <nav-link route="Adoptenme" text="Adoptenme"></nav-link>
        <nav-link route="About" text="Acerca de"></nav-link>
      </div>
    </div>
  </div>
</template>

<script>
import NavLink from '../components/nav-link/NavLink.vue'

export default {
  name: 'NavBarLayout',
  components: {
    NavLink
  }
}
</script>
